import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { graphql } from 'gatsby'

import theme from '../config/theme'
import Page from '../components/layouts/Page'
import Container from '../components/parts/Container'
import Heading from '../components/parts/Heading'
import Pattern from '../components/parts/Pattern'
import SvgPattern from '../static/images/patterns/two.svg'
import AccessoriesList from '../components/parts/AccessoriesList'

const HeadingContainer = styled.div`
  position: relative;
  padding-top: 5rem;
  padding-bottom: 18rem;
  overflow: hidden;
  background-color: ${theme.palette.yellow[1]};
`

const HeadingStyled = styled(Heading)`
  font-weight: bold;
  font-size: 3rem;
  font-family: ${theme.fonts.libreBaskerville};
  letter-spacing: 1.25px;
  ${theme.media.smDown} {
    font-size: 2rem;
    letter-spacing: 0.8px;
  }
`

const ContainerStyled = styled(Container)`
  transform: translateY(-13rem);
  ${theme.media.smDown} {
    transform: translateY(-17rem);
  }
`

const Accessories = ({
  data: {
    collections: { products: items },
  },
}) => (
  <Page title="Zubehör" description="" backgroundColor={theme.palette.yellow[1]}>
    <HeadingContainer>
      <Pattern top="0" right="0" transformX="30%" zIndex="0">
        <SvgPattern />
      </Pattern>
      <Container>
        <HeadingStyled level={1}>Zubehör & Services?</HeadingStyled>
      </Container>
    </HeadingContainer>
    <ContainerStyled>
      <AccessoriesList items={items} />
    </ContainerStyled>
  </Page>
)

Accessories.propTypes = {
  data: PropTypes.shape({
    collections: PropTypes.shape({
      products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    }).isRequired,
  }).isRequired,
}

export default Accessories

export const query = graphql`
  {
    collections: allShopifyProduct(
      filter: { collections: { elemMatch: { title: { in: ["ACCESSORIES"] } } } }
    ) {
      products: nodes {
        ...SingleAccessory
      }
    }
  }
`
